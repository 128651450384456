import React from 'react';
import PropTypes from 'prop-types';

import '../style/tool.scss';

export const CLASS_NAME = 'tool';

export class Tool extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['normal', 'separated']),
  };

  static defaultProps = {
    children: null,
    onClick: () => {},
    type: 'normal',
  };

  render() {
    const { children, onClick, type } = this.props;
    const className = `${CLASS_NAME} ${CLASS_NAME}--${type}`;

    return (
      <button className={className} onClick={onClick} type="button">
        {children}
      </button>
    );
  }
}
