import React from 'react';
import PropTypes from 'prop-types';
import { WindowScroller } from 'react-virtualized';
import DocumentTitle from 'react-document-title';
import sanskritTranscoder from 'sanskrit-transcoder';
import { withRouter } from '../util/with-router';

import { URLTool } from '../util/url-tool';

import { Layout } from '../components/layout';
import { Search } from '../components/search';
import { ContentPageTitle } from '../components/content-page-title';
import { ContentSection } from '../components/content-section';
import { ContentBody } from '../components/content-body';
import { ContentList } from '../components/content-list';
import { ContentSubtext } from '../components/content-subtext';
import { DictionaryEntry } from '../components/dictionary-entry';
import { TranscodingSelectors } from '../components/transcoding-selectors';
import { Tool } from '../components/tool';

import Logo from '../components/logo';

class Home extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      params,
      location,
      navigate,
    } = props;

    const { searchString = '' } = params;

    this.urlTool = new URLTool(location, navigate);

    this.state = {
      isToolsExpanded: false,
      isActive: !!searchString,
    };
  }

  componentDidUpdate() {
    const { location, navigate } = this.props;

    this.urlTool.update(location, navigate);
  }

  get params() {
    const { params } = this.props;

    return params;
  }

  handleToggleTools = () => {
    const { isToolsExpanded } = this.state;

    this.setState({
      isToolsExpanded: !isToolsExpanded,
    });
  };

  renderTitle() {
    const { isActive } = this.state;
    const title = 'saṁskṛta › english';

    return (
      <ContentSection type={isActive ? 'hidden' : 'normal'}>
        <ContentPageTitle>
          <Logo />
          {title}
        </ContentPageTitle>
        <ContentBody>
          An efficient, practical dictionary tool to search for the english interpretation of sanskrit words.
        </ContentBody>
      </ContentSection>
    );
  }

  renderBody() {
    const { isActive } = this.state;

    if (!isActive) {
      return (
        <ContentSection>
          <ContentBody>
            <strong>Features</strong>
          </ContentBody>
          <ContentList>
            <li>Hyperlink navigation between words.</li>
            <li>Permalink encode word and transcoding preferences.</li>
            <li>Simple, easy to read design.</li>
            <li>Light and dark UI modes (based on OS).</li>
            <li>Transcoding schemes: <strong>HK</strong>, <strong>SLP1</strong>, <strong>Roman</strong>, <strong>Devanagari</strong>, <strong>WX</strong>, <strong>ITRANS</strong></li>
          </ContentList>
        </ContentSection>
      );
    }

    return null;
  }

  renderSubtext() {
    const { isActive } = this.state;

    if (!isActive) {
      return (
        <ContentSection>
          <ContentSubtext>
            All entries are from the Digital Edition of the <a href="https://www.sanskrit-lexicon.uni-koeln.de/scans/MWScan/2020/web/index.php" rel="noopener noreferrer" target="_blank">Monier-Williams Sanskrit-English Dictionary</a> and licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/3.0/" rel="noopener noreferrer" target="_blank">CC Sharealike 3.0</a>.
          </ContentSubtext>
          <ContentSubtext>
            Site code and design Copyright © 2025 <a href="https://arjunmehta.net/">Arjun Mehta</a>
          </ContentSubtext>
        </ContentSection>
      );
    }

    return null;
  }

  renderSearch(windowConfig) {
    const { transcodeFrom, transcodeTo, searchString = '' } = this.params;
    const { isToolsExpanded } = this.state;

    return (
      <Search
        windowConfig={windowConfig}
        autoStart
        transcodeFrom={transcodeFrom}
        transcodeTo={transcodeTo}
        currentValue={searchString}
        preProcessor={(search) => {
          return sanskritTranscoder(search, transcodeFrom, 'slp1');
        }}
        onChange={(value) => {
          this.setState({ isActive: !!value });
        }}
        onClear={() => {
          this.setState({
            isActive: false,
          }, () => {
            if (searchString) {
              this.urlTool.navigateTo(`/${transcodeFrom}/${transcodeTo}`);
            }
          });
        }}
        onMatch={(value, matches) => {
          this.setState({ isActive: !!matches }, () => {
            if (matches && matches.exactMatch) {
              const destination = `/${transcodeFrom}/${transcodeTo}/${value}`;
              this.urlTool.navigateTo(destination);
            }
          });
        }}
        optionComponent={isToolsExpanded ? null : (
          <Tool onClick={this.handleToggleTools}>{`${transcodeFrom} to ${transcodeTo}`}</Tool>
        )}
        renderElement={({ type, item, index, total }) => {
          const { key: keyname, entries } = item;

          return (
            <DictionaryEntry
              key={`dictionary-entry-${keyname}-${index}`}
              keyname={keyname}
              total={total}
              type={type}
              index={index}
              entries={entries}
              transcodeFrom={transcodeFrom}
              transcodeTo={transcodeTo}
            />
          );
        }}
      />
    );
  }

  renderTools() {
    const { isToolsExpanded } = this.state;
    const { transcodeFrom, transcodeTo } = this.params;

    return (
      <TranscodingSelectors
        isCollapsed={!isToolsExpanded}
        to={transcodeTo}
        from={transcodeFrom}
        toggleTools={() => { this.setState({ isToolsExpanded: !isToolsExpanded }); }}
        onFromChange={(value) => {
          const currentpath = this.urlTool.getCurrentPath();
          const deconstructed = currentpath.split('/');

          if (deconstructed[3]) {
            const transcoded = sanskritTranscoder(deconstructed[3], transcodeFrom, value);
            deconstructed[3] = transcoded;
          }

          deconstructed[1] = value;
          this.urlTool.replaceTo(deconstructed.join('/'));
        }}
        onToChange={(value) => {
          const currentpath = this.urlTool.getCurrentPath();
          const deconstructed = currentpath.split('/');

          deconstructed[2] = value;
          this.urlTool.replaceTo(deconstructed.join('/'));
        }}
      />
    );
  }

  render() {
    const { transcodeFrom, transcodeTo, searchString = '' } = this.params;
    let title = 'Sanskrit-English Dictionary';

    if (transcodeFrom && transcodeTo && searchString) {
      const transcoded = sanskritTranscoder(searchString, transcodeFrom, transcodeTo);
      title = `${transcoded} - ${title}`;
    }

    return (
      <DocumentTitle title={title}>
        <WindowScroller>
          {(windowConfig) => (
            <Layout>
              {this.renderTitle()}
              {this.renderTools()}
              {this.renderSearch(windowConfig)}
              {this.renderBody()}
              {this.renderSubtext()}
            </Layout>
          )}
        </WindowScroller>
      </DocumentTitle>
    );
  }
}

export const HomeRoute = withRouter(Home);
