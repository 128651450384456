import React from 'react';

export function ClearIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9999 15.1716L13.0502 10.2218L10.2217 13.0502L15.1715 18L10.2217 22.9497L13.0501 25.7782L17.9999 20.8284L22.9496 25.7782L25.7781 22.9497L20.8283 18L25.7781 13.0503L22.9496 10.2218L17.9999 15.1716Z" fill="#888" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9999 15.1716L13.0502 10.2218L10.2217 13.0502L15.1715 18L10.2217 22.9497L13.0501 25.7782L17.9999 20.8284L22.9496 25.7782L25.7781 22.9497L20.8283 18L25.7781 13.0503L22.9496 10.2218L17.9999 15.1716Z" fill="#888" />
    </svg>
  );
}
